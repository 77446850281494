<template>
    <div id="dataScreen" ref="dataScreen">
        
        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width">
              <div class="react-left ml-4 react-l-s">
                <span class="react-left"></span>
                <!--<span class="text">数据分析</span>-->
                <span class="text" @click="deviceFunEvent('bodyBox')">数据分析</span>
              </div>
              <div class="react-left ml-3" @click="quit">
              <!-- <div class="react-left ml-3"> -->
                <span class="text">{{curGroupName}}</span>
              </div>
              <!-- <div class="react-left ml-3">
                <span class="text"></span>
              </div> -->
            </div>
            <div class="d-flex aside-width">
              <!-- <div class="react-right bg-color-blue mr-3">
                <span class="text fw-b">{{curMachine}}</span>
              </div> -->
              <!-- <div class="react-right bg-color-black mr-3">
                <span class="text fw-b"></span>
              </div> -->
              <!--
              <div class="react-right bg-color-black mr-3">
                <span class="text fw-b">{{curMachine}}</span>
              </div>
              -->
              <div class="react-right bg-color-black mr-3">
                <span class="text fw-b" @click="deviceFunEvent('control')">{{curMachine}}</span>
              </div>
              <div class="react-right mr-4 react-l-s">
                <span class="react-after"></span>
                <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
              </div>
            </div>
          </div>

          <div class="body-box" :style="{display:deviceFunDisplay}">
            <deviceFun ref="deviceFun" v-if="deviceFunShow"/>
          </div>
          <div class="body-box" :style="{display:bodyBoxDisplay}">
            
            <!-- 第三行数据 -->
            <div class="content-box">
              <div>
                <dv-border-box-12>
                  <centerLeft1 />
                </dv-border-box-12>
              </div>
              <div>
                <dv-border-box-12>
                  <pageGroupList v-if="pageGroupListShow"/>
                  <centerLeft2 v-else/>
                </dv-border-box-12>
              </div>
              <div>
                <dv-border-box-13>
                  <centerLeft3 />
                </dv-border-box-13>
              </div>
              <!-- 中间 -->
              <div>
                <center />
              </div>
              <!-- 中间 -->
              
              <div>
                <dv-border-box-12>
                  <!-- <centerRight1 /> -->
                  <!-- 报错：domObserver.disconnect is not a function at VueComponent.unbindDomResizeCallback
                  <centerRight1 v-if="webcamShow"/>
                  <centerRight3 v-else/> 
                  -->
                  <centerRight1 v-if="webcam1Show" />
                  <centerRight2 v-if="webcam2Show" />
                  <centerRight3 v-if="pumpStatusShow" />
                  <!-- <deviceLightValue v-if="pumpStatusShow" /> -->
                </dv-border-box-12>
              </div>
              
              <!-- 
              <div>
                <centerRight2 />
              </div>
              -->
              
            </div>

            <!-- 第四行数据 -->
            <div class="bottom-box">
              <dv-border-box-13>
                <bottomLeft />
              </dv-border-box-13>
              <dv-border-box-12>
                <bottomRight />
              </dv-border-box-12>
            </div>
        </div>
        <!-- 退出确认模态框 -->
        <modal 
          title="提示" 
          :content='modalContent'
          :showCancle='showCancle' 
          @on-cancel='quitCancel'
          @on-confirm='quitConfirm'
          v-show='showModal'>
        </modal>
    </div>

</template>
<script>
import { formatTime } from '../utils/index.js'
import Transfer from "../utils/transfer.js";
import centerLeft1 from './centerLeft1'
import centerLeft2 from './centerLeft2'
import pageGroupList from '../group/index'
import centerLeft3 from './centerLeft3'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import centerRight3 from './centerRight3'
import center from './center'
import bottomLeft from './bottomLeft'
import bottomRight from './bottomRight'

import deviceFun from '../device/deviceFun'
// import deviceLightValue from '../device/deviceLightValue'



export default {
  data() {
    return {
      timing: null,
      loading: false,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      navItemTitle: '上蔬源可视化平台',
      curGroupName: this.$store.state.globalData.curGroupName,
      // curGroupName: this.$GLOBAL.curGroupName,
      curMachine: '',
      showCancle: false,
      modalContent: '确定要退出登录吗?',
      showModal: false,
      webcam1Show: false,
      webcam2Show: false,
      pumpStatusShow: true,
      bodyBoxDisplay: "block",
      deviceFunDisplay: "none",
      deviceFunShow: false,
      pageGroupListShow: false,
      
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    pageGroupList,
    centerLeft3,
    centerRight1,
    centerRight2,
    centerRight3,
    center,
    bottomLeft,
    bottomRight,
    deviceFun,
    // deviceLightValue,
  },
  mounted() {
    
    this.timeFn();
    // this.cancelLoading();
    // this.changeGroup();
    var globalData = this.$store.state.globalData;
    this.pageGroupListShow = globalData.groupListShow ? true : false;

    if(globalData.webcamShow == 1) {
      //1：国标GB28181, 2:海康-萤石
      if(globalData.webcamType == 1) {
        this.webcam1Show = false;
        this.webcam2Show = true;
      } else if(globalData.webcamType == 2) {
        this.webcam1Show = true;
        this.webcam2Show = false;
      }
      
      this.pumpStatusShow = false;
      
    }
  
    
    Transfer.$on('navDataScreen', (msg) => {
      // console.log(msg);
      
      this.curMachine = globalData.curMachine;
      this.pageGroupListShow = globalData.groupListShow ? true : false;
      
      Transfer.$emit('center','msg-center');
      
      if(globalData.webcamShow == 1) {
        //1:国标GB28181, 2:海康-萤石
        if(globalData.webcamType == 1) {
          Transfer.$emit('centerRight2','msg-centerRight2');
        } else if(globalData.webcamType == 2) {
          Transfer.$emit('centerRight1','msg-centerRight1');
        }
        
      } else {
        Transfer.$emit('centerRight3','msg-centerRight3');
      }
      
      Transfer.$emit('bottomLeft','msg-bottomLeft');
      Transfer.$emit('bottomRight','msg-bottomRight');
    });

    Transfer.$on('bottomRight-index-center', (msg) => {
      Transfer.$emit('center-child','msg-center-child');
    });

    //国标摄像头
    Transfer.$on('navDataScreen-index-gbControl', (msg) => {
      this.$store.commit("setMachineLoop", false);
      Transfer.$emit('index-gbControl', msg);
    });

    Transfer.$on('navDataScreen-centerRight2', (msg) => {
      this.$store.commit("setMachineLoop", true);
      Transfer.$emit('centerRight2-replay', msg);
    });

    //萤石云摄像头
    Transfer.$on('navDataScreen-index-cameraControl', (msg) => {
      this.$store.commit("setMachineLoop", false);
      Transfer.$emit('index-cameraControl', msg);
    });
    Transfer.$on('navDataScreen-centerRight1', (msg) => {
      this.$store.commit("setMachineLoop", true);
      Transfer.$emit('centerRight1-replay', msg);
    });

  },
  beforeDestroy () {
    clearInterval(this.timing);
    this.timing = null;
    // var globalDataSrc = this.$store.state.globalData;
    
    var globalData = {
      curGroupName: null,
      curGroupId: null,
      curMachine: '',
      curWebcamSerial: '',
      curStyleSize: null,
      curProtocolType: null,
      curLayerNum: null,
			curLayerFan: null,
      accessToken: null,
      loginToken: null,
      pumpNum: 1,
      curPumpNo: 1,
      envSensorNum: 1,
      curEnvSensorNo: 1,
      waterSensorNum: 1,
      curWaterSensorNo: 1
    }
    // globalData.curGroupName = globalDataSrc.curGroupName;
    // globalData.curGroupId = globalDataSrc.curGroupId;
    
    this.$store.commit("setGlobalData", globalData);
  },
  methods: {
    quit() {
      var globalData = this.$store.state.globalData;
      if(globalData.quitBtnValid != 1) {
        return;
      }
      //切换分组
      this.showCancle = true;
      this.showModal = true;
    },
    quitCancel() {
      this.showCancle = false;
      this.showModal = false;
    },
    quitConfirm() {
      //退出登录
      this.$router.push({
        path: '/'
      });
      this.$destroy();
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    deviceFunEvent(nav) {
      
      if(nav == "control") {
        if(this.bodyBoxDisplay == "none") return;
        this.$store.commit("setMachineLoop", false);

        var globalData = this.$store.state.globalData;
        var msg = {};
        msg.curMachine = globalData.curMachine;
        msg.curOnline = globalData.curOnline;
        msg.curStyleSize = globalData.curStyleSize;
        msg.curProtocolType = globalData.curProtocolType;
        msg.curLayerNum = globalData.curLayerNum;
        msg.curLayerFan = globalData.curLayerFan;
        
        setTimeout(() => {
          Transfer.$emit('deviceFun', msg);
        }, 100);
        //Transfer.$emit('deviceFun', msg);
        
        this.bodyBoxDisplay = "none";
        this.deviceFunDisplay = "block";
        this.deviceFunShow = true;
        
      } else {
        this.$store.commit("setMachineLoop", true);

        this.bodyBoxDisplay = "block";
        this.deviceFunDisplay = "none";
        this.deviceFunShow = false;
      }
      

    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>